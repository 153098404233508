import { defineRule, configure } from 'vee-validate';
import * as rules from '@vee-validate/rules';

import { i18n } from './i18n'


Object.keys(rules).forEach(rule => {
    if (rule == "default") {
        //..yeah...kinda funky...
        return;
    }
    defineRule(rule, rules[rule]);
});

configure({
    generateMessage: function (context) {
        context.field = i18n.global.t('fields.' + context.field).toLowerCase();
        return i18n.global.t('validations.' + context.rule.name, context);
    }
})