<template >
  <div class="dropdown">
    <button class="dropbtn">
      <img src="/img/bandera-negra.svg" class="bandera-negra" alt="bandera">
      <img src="/img/bandera.svg" class="bandera" alt="bandera">
      <span class="idioma">{{ $t('web.idiomas.' + $i18n.locale) }}</span>
      <img src="/img/flecha-select.svg" class="flecha" alt="flecha">
      <img src="/img/flecha-negra.svg" class="flecha-oscura" alt="flecha">

    </button>
    <div class="dropdown-content">
      <a v-for="(lang, key) in $tm('web.idiomas')" :key="key" @click="setLocale(key)" v-show="key != $i18n.locale">
        {{ (lang) }}
      </a>
    </div>
    <loading :active='isLoading' :loader='"none"' />

  </div>
</template>
  
<script>
import Loading from 'vue-loading-overlay';

export default {
  name: "language-selector",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    setLocale(lang) {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false
      }, 1000);
      let name = this.$router.name;
      let params = this.$router.params;
      if (params) {
        params.lang = lang;
      } else {
        params = {
          lang: lang
        };
      }
      this.$gtag.set({
        language: lang,
      });
      document.documentElement.setAttribute("lang", lang);
      this.$router.push({ name: name, params: params });
    },
  }
}
</script>
  
  
<style lang="scss" scoped>
.flecha {
  margin-left: 10px;
  padding-bottom: 2px;
}

.bandera {
  margin-right: 10px;
}

.dropbtn {
  background-color: transparent;
  color: #1E2F3F;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  padding-left: 0px;
}
.footer .dropbtn{
  padding-top:0px;

}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #1E2F3F;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #1E2F3F;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}
</style>