<template lang="html">
  <section class="footer">
    <div class="container d-flex jcsb aifs list-footer">
      <div class="grid">
        <router-link :to="{ name: 'home' }" class="logo">
          <img src="/img/logo.svg" alt="R-sami logo" />
        </router-link>
        <p>
          {{ $t("web.footer.text") }}
        </p>
      </div>
      <div class="grid">
        <p class="title -contact">
          {{ $t("web.footer.contact") }}
        </p>
        <p class="icon-location">
          Carrer Gremi des Fusters 33 07009 Palma de Mallorca
        </p>
        <a href="mailto:support@r-sami.com" class="icon-mail">support@r-sami.com</a>
        <a href="tel:+34919498458 " class="icon-phone">+34 919 49 84 58</a>
      </div>
      <div class="grid">
        <p class="title">{{ $t("web.footer.pages") }}</p>
        <router-link :to="{ name: 'about' }">
          {{ $t("web.footer.about") }}
        </router-link>
        <router-link :to="{ name: 'features' }">
          {{ $t("web.footer.features") }}
        </router-link>
        <router-link :to="{ name: 'contact' }">
          {{ $t("web.footer.contact") }}
        </router-link>
        <a href="/en/investor"> Investor Relations </a>
      </div>
      <div class="grid">
        <p class="title">{{ $t("web.footer.other") }}</p>
        <router-link :to="{ name: 'privacy' }">
          {{ $t("web.footer.privacy") }}
        </router-link>
        <router-link :to="{ name: 'support' }">
          {{ $t("web.footer.support") }}
        </router-link>
      </div>
      <div class="grid">
        <language></language>

        <div class="rss">
          <p class="title">
            {{ $t("web.footer.follow") }}
          </p>
          <a href="https://www.linkedin.com/company/r-sami/about/" target="_blank">
            <img src="/img/icons/linkedin.svg" alt="" />
          </a>
        </div>

        <div class="rss download-icons">
          <p class="title">
            {{ $t("web.footer.download") }}
          </p>
          <a href="https://apps.apple.com/gb/app/r-sami/id1625300250" target="_blank" title="Download on the App Store"
            class="apple">
            <img src="/img/icons/apple.svg" alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.rsami.rsamimobile" target="_blank"
            title="Get in on Google Play">
            <img src="/img/icons/google.svg" alt="Get in on Google Play" />
          </a>
        </div>
      </div>
    </div>
    <div class="container next-generation">
      <div class="title" v-html="$t('web.footer.next_generation')"></div>
      <div class="logos d-flex">
        <img border="0" src="/img/footer/logo_secretaria_digitalizacion_e_inteligencia_artificial.svg" />
        <img border="0" src="/img/footer/logo_red_es.svg" />
        <img border="0" src="/img/footer/logo_kit_digital.svg" />
        <img border="0" src="/img/footer/logo_next_generation.svg" />
      </div>
    </div>
    <div class="subfooter container">
      <div class="logos-app">
        <a href="https://www.getapp.com/operations-management-software/a/r-sami/reviews/" target="_blank">
          <img border="0" src="https://www.getapp.com/ext/reviews_widget/v1/dark/r-sami-application" />
        </a>
        <a class="logofooterdos" target="_blank"
          href="https://www.capterra.com/reviews/266909/R-SAMi?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
          <img border="0"
            src="https://assets.capterra.com/badge/b09a84a9d18253db6db1a75a97540f42.svg?v=2216743&p=266909" />
        </a>
        <a href="https://www.softwareadvice.com/fleet-management/r-sami-profile" target="_blank">
          <img border="0" src="https://badges.softwareadvice.com/reviews/a615b8cd-e093-4c2c-b0af-bbb6dd3c654d" />
        </a>
        <img border="0" src="/img/enisa.svg" />

      </div>
      <p class="title-small">
        {{ $t("web.footer.copyright") }}
      </p>
    </div>
  </section>
</template>

<script lang="js">

import language from '@/components/language';

export default {
  name: 'foot',
  components: {
    'language': language,
  },


}
</script>

<style scoped lang="scss">
@import "../styles/general.scss";

.footer {
  background-color: #1e2f3f;
  background-image: url("/img/bg/bg-web.footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .list-footer {
    padding-top: 20px;
    padding-bottom: 20px;

    .grid {
      width: calc(100% / 5);
      padding-left: 20px;

      &:first-of-type {
        padding-left: 0;
      }

      .logo {
        img {
          width: 100%;
          margin-bottom: 20px;
          display: inline-block;
        }
      }

      a,
      p {
        color: white;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.4em;
        text-align: left;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;

        &.-contact {
          padding-left: 30px;
        }
      }

      .icon-location {
        margin-bottom: 15px;
      }

      a {
        text-decoration: none;
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
      }

      .language {
        color: white;
        background: transparent;
        font-size: 16px;
        border: 0;
        background-image: url(/img/icons/icon-flag.svg);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 26px;
        min-height: 25px;
        background-size: 20px;
        margin-bottom: 20px;

        &:focus {
          outline: none;
          border: 0;
        }
      }

      .rss {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        a {
          width: 25px;
          margin-right: 10px;
        }

        &.download-icons {
          a {
            margin-right: 0px;
            width: 20px;

            img {
              width: 100%;
            }
          }

          & .apple {
            margin-right: 10px !important;
          }
        }
      }
    }
  }

  .subfooter {
    background: $blueDark;
    padding: 10px 0;
    border-top: 1px solid $blueLight;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    p {
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .next-generation {
    padding: 20px 0;
    .title {
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 16px;
      margin-bottom: 35px;
      position: relative;
      ::before {
        content: '';
        width: 200px;
        height: 2px;
        background: white;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -10px;
        margin: 0 auto;
      }
    }

    .logos {
      justify-content: center;
      margin-bottom: 25px;
      img {
        height: 65px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer .list-footer .grid {
    width: 30%;
    padding-left: 0;
  }
}

@media screen and (max-width: 780px) {
  .footer .list-footer .grid {
    width: 30%;
    padding-left: 0;
  }

  .footer .list-footer .grid .logo img {
    max-width: 200px;
  }

  .footer .list-footer .grid .title.-contact {
    padding-left: 0;
  }

  .footer .list-footer .grid:first-of-type {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .footer .list-footer .grid:first-of-type a {
    width: 220px;
  }

  .footer .list-footer .grid:first-of-type p {
    width: calc(100% - 240px);
  }
}

@media screen and (max-width: 600px) {

  .footer .list-footer .grid .title,
  .footer .list-footer .grid a {
    text-align: left;
    margin-bottom: 5px;
  }

  .footer .list-footer .grid .rss {
    justify-content: center;
  }

  .footer .list-footer .grid:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    a {
      width: max-content;
    }
  }

  .footer .list-footer .grid {
    width: 100%;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .footer .list-footer .grid:first-of-type p {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .footer .grid:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer .next-generation {
    padding: 0 20px;
    .title {
      font-weight: 300;
    }
    .logos {
      margin-bottom: 15px;
        img {
          height: 55px;
          margin-right: 10px;
        }
    }
  }
}</style>
