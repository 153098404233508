<template>
  <Header></Header>
  <div class="body-app">
    <router-view v-slot="{ Component }">
      <transition name='custom-classes' enter-active-class="animate__animated animate__fadeIn animate__slow">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <loading :active='isLoading' :loader='"none"' />

  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Loading from 'vue-loading-overlay';

export default {
  name: 'App',
  components: {
    Header: Header,
    Footer: Footer,
    Loading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 1000);
  },
}
</script>
<style lang="scss">
@import "@/styles/general.scss";

.body-app {
  padding-top: 65px;
}
@media screen and (max-width:897px){
  .body-app {
        padding-top: 57px;
    }
}
</style>
