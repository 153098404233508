<template lang="html">
  <section class="header">
    <div class="encabezado-top">
      <div class="container">
        <router-link :to="{ name: 'home' }" class="logo">
          <img src="/img/logo-negro.svg" alt="R-sami logo">
        </router-link>
        <ul class="item-list d-flex aic jcfe" :showMenuResponsive="showMenuResponsive">
          <span class="detail-1"></span>
          <span class="detail-2"></span>

          <li class="contacta">
            <p class="btn-negro">{{ $t('web.menu.contacta') }}</p>
            <a href="mailto:support@r-sami.com"><img src="/img/icons/mail-negro.svg" alt="icono mail"></a>
            <a href="tel:+34919498458"><img src="/img/icons/telefono-negro.svg" class="tel" alt="icono telefono"></a>

          </li>
          <li>
            <router-link :to="{ name: 'demo' }" class="btn-destacado">{{ $t('web.menu.demo') }}</router-link>
          </li>
          <li class="idioma">
            <language></language>
          </li>
        
            

       
        </ul>

        <button class="menu" @click="toggleShowSubMenuResponsive" :showMenuResponsive="showMenuResponsive">
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="encabezado-bottom">
      <div class="container">

        <ul class="item-list d-flex aic jcfe" :showMenuResponsive="showMenuResponsive">
          <span class="detail-1"></span>
          <span class="detail-2"></span>
          <li class="first">
            <router-link :to="{ name: 'home', params: { lang: $i18n.locale } }">{{ $t('web.menu.home') }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'about', params: { lang: $i18n.locale } }">{{ $t('web.menu.about')
            }}</router-link>
          </li>

          <li>
            <router-link :to="{ name: 'features', params: { lang: $i18n.locale } }">{{ $t('web.menu.features') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'support', params: { lang: $i18n.locale } }"
              :class="[$route.name == 'support-how' || $route.name == 'support-started' || $route.name == 'support-release' ? 'router-link-active' : '']">
              {{ $t('web.menu.support') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'blog', params: { lang: $i18n.locale } }"
              :class="[$route.name == 'blog-page' ? 'router-link-active' : '']">
              {{ $t('web.menu.blog') }}
            </router-link>
          </li>


          <li>
            <router-link :to="{ name: 'contact', params: { lang: $i18n.locale } }">{{ $t('web.menu.contact') }}
            </router-link>
          </li>
          <li class="zona-dsp" v-if="$i18n.locale == 'es'">
            <a href="https://dsp.r-sami.com/" target="_blank">ZONA DSP ESPAÑA</a>
          </li>
          <div class="responsive">
            <li v-if="$i18n.locale == 'es'">
              <a href="https://dsp.r-sami.com/" target="_blank" class="btn-destacado responsive">ZONA DSP ESPAÑA</a>
            </li>
            <li>
              <router-link :to="{ name: 'demo', params: { lang: $i18n.locale } }" class="btn-destacado responsive">{{
                  $t('web.menu.demo')
              }}</router-link>
            </li>
            <li class="idioma menu-responsive">
              <language></language>
            </li>
          </div>

        </ul>

        <button class="menu" @click="toggleShowSubMenuResponsive" :showMenuResponsive="showMenuResponsive">
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
        </button>
        <router-link :to="{ name: 'home', params: { lang: $i18n.locale } }" c>
          <img src="/img/logo.svg" class="logo-responsive" alt="logo r-sami">
        </router-link>

      </div>
    </div>
  </section>







</template>

<script lang="js">
import language from '@/components/language';
import { useI18n } from 'vue-i18n'

export default {
  name: 'heade',
  components: {
    'language': language,
  },
  props: [],
  data() {
    return {
      showMenuResponsive: false,
    }
  },
  methods: {
    toggleShowSubMenuResponsive: function () {
      this.showMenuResponsive = !this.showMenuResponsive;
      if (this.showMenu == true) {
        this.showMenu = false
      }
    },
  },
  watch: {
    "$route.path"() {
      this.showMenuResponsive = false
    }
  }
}


</script>

<style scoped lang="scss">
@import "../styles/general.scss";



.tel {
  margin-top: 3px !important;
}

.responsive,
.logo-responsive {
  display: none
}

.header .container .item-list li a.router-link-active.btn-destacado,
.header .container .item-list li a.btn-destacado:hover {
  color: #0AA6DF;
  color: white;
}

.header {
  background: $blueDark;
  padding: 10px 0;
  border-bottom: 2px solid white;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
  padding-bottom: 0px;

  &.fixed-class {
    padding: 5px;
    background: #1e2f3feb;
    border-bottom: 0;
    box-shadow: 0px 6px 21px -12px rgba(0, 0, 0, 0.75);
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

    .container .logo {
      max-width: 190px;
      width: 180px;
      transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 200px;
      transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

      img {
        width: 180px;
        padding-left: 40px;
      }
    }

    .item-list {
      list-style: none;
      /* width: calc(100% - 600px); */
      // width: calc(100% - 350px);
      width: 100%;
      padding: 0 20px;
      /* justify-content: flex-start; */

      li {
        margin-left: 25px;
        //width: calc(100% / 6 - 40px);
        width: auto;

        a {
          color: #fff;
          text-decoration: none;
          font-size: 17px;
          font-weight: 600;
          position: relative;
          border-radius: 100px;
          text-align: center;

          &::before {
            content: '';
            width: 0;
            height: 1px;
            background: $blueLight;
            position: absolute;
            bottom: -3px;
            right: 0;
            left: 0;
            transition: all 0.5s ease-in-out;
          }

          &.router-link-active,
          &:hover {
            color: $blueLight;

            &::before {
              width: 100%;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }

    .login-search {
      max-width: 180px;
      padding: 0;

      .btn-login {
        color: white;
        background: $blueLight;
        padding: 10px 20px;
        text-decoration: none;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
      }

      .btn-search {
        width: 30px;
        height: 30px;
        background-image: url('/img/icons/icon-search.svg');
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        background-size: 29px;
      }
    }
  }
}

.menu {
  display: none;
}

.detail-1,
.detail-2 {
  display: none;
}

.encabezado-bottom .item-list li {
  padding: 15px;
  margin-left: 0px !important;
}

.encabezado-bottom {
  margin-left: 30px !important;
}

.zona-dsp {
  background: #0AA6DF;
  text-align: center;
  padding: 15px !important;
}

.header .container .item-list li.zona-dsp a::before {
  color: white !important;
}

.header .container .item-list li.zona-dsp a:hover {
  color: white !important;
}

@media screen and (max-width:1121px) {
  .header .container{
    padding:20px;
  }
  .header .container .item-list{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

  .header .container .item-list li {
    margin-left: 9px;
    width: auto;
  }
}

@media screen and (max-width:897px) {
  .header {
    padding-bottom: 10px;
  }

  .zona-dsp {
    display: none;
  }

  .encabezado-bottom .item-list li {
    padding: 2px !important;
  }

  .btn-destacado.responsive {
    font-size: 3vh !important;
  }

  .encabezado-top {
    display: none
  }

  .responsive,
  .logo-responsive {
    display: block
  }

  .responsive {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .logo-responsive {
    width: 150px
  }

  .encabezado-bottom .container .item-list {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .menu {
    display: block;
    --color: #fff;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    outline: none;
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;

    svg {
      width: 64px;
      height: 48px;
      top: -6px;
      left: -14px;
      stroke: var(--color);
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      display: block;
      position: absolute;

      path {
        transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0);

        &:nth-child(2) {
          --duration: .7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px;
        }

        &:nth-child(3) {
          --offset: 133px;
          --array-2: 107px;
        }
      }
    }

    &[showmenuresponsive="true"] {
      svg {
        path {
          --offset: 57px;

          &:nth-child(1),
          &:nth-child(3) {
            --delay: .15s;
            --easing: cubic-bezier(.2, .4, .2, 1.1);
          }

          &:nth-child(2) {
            --duration: .4s;
            --offset: 2px;
            --array-1: 1px;
          }

          &:nth-child(3) {
            --offset: 58px;
          }
        }
      }
    }
  }

  .header .container .item-list {
    position: fixed;
    bottom: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $blueDark;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    &[showmenuresponsive="true"] {
      bottom: 0;
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    }

    .detail-1 {
      display: block;
      width: 50vw;
      height: 50vh;
      background-image: url('/img/icons/detail-line2.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: -81px;
      top: -92px;
      animation: flash 15s infinite;
      /* opacity: 0.5; */

    }

    .detail-2 {
      display: block;
      width: 50vw;
      height: 50vh;
      background-image: url('/img/icons/detail-line1.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -72px;
      bottom: 0;
      animation: flash 30s infinite;
    }
  }



  .header .container .item-list li {
    margin-left: 0;

  }

  .header .container .item-list li a {
    font-size: 4vh;
    margin-bottom: 2vh;
    display: inline-block;
  }

  .header .container {
    justify-content: space-between;
  }
}

@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 0.8;
  }

  25%,
  75% {
    opacity: 0.2;
  }
}

.btn-destacado {
  background-color: #0AA6DF;
  color: white;
  font-size: 18px;
  letter-spacing: 0.3;
  font-weight: 700;
  padding: 12px 18px;
  border-radius: 100px;
  text-align: center;
}

.btn-destacado::before {
  display: none
}

.idioma {
  margin-top: 6px;

}


.encabezado-top {
  background: white;
  margin-top: -10px;

  & .btn-negro {
    color: #1E2F3F !important;
  }
}

.encabezado-bottom {
  background: #1E2F3F
}

.contacta {
  display: flex;
  flex-direction: row;

  & img {
    margin: 0px 10px
  }
}




.encabezado-bottom {


  & .container .item-list {
    display: flex;
    justify-content: flex-start;
  }
}


.encabezado-bottom .item-list li.first {
  padding-left: 0px !important;
}
</style>