import { createApp } from 'vue'
import { createPinia } from 'pinia';

import axios from 'axios'
import VueAxios from 'vue-axios'

import Vue3Lottie from 'vue3-lottie'
import VueGtag from 'vue-gtag'

import { i18n } from './i18n'
import './vee-validate'

import router from './router'
import App from './App.vue'


//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export const pinia = createPinia();
const app = createApp(App);
app.use(pinia)
app.use(i18n)
app.use(VueAxios, axios)
app.use(Vue3Lottie)
app.use(router)



app.use(LoadingPlugin)

if (process.env.VUE_APP_GOOGLE_ID) {
    app.use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GOOGLE_ID
        },
        pageTrackerTemplate(to) {
            return {
              language: to.params.lang ?? "en",
              page_path: to.path
            }
        }
    }, router);
}
app.mount('#app');

export default app;
