import { createI18n } from 'vue-i18n'
import utils from "@/utils/utils.js";

let lang = utils.getLocale() ?? "en";
let part = location.pathname.split("/")[1] ?? "";
if (part.length == 2) {
    lang = part;
}
let messages = loadLocaleMessages();
if(messages[lang] == null){
  lang = 'en';
}
document.documentElement.setAttribute("lang", lang);

export const i18n =  createI18n({
  legacy: false,
  locale: lang ,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages,
  globalInjection: true,
});

export function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\/([A-Za-z0-9-_]+)/i)
    if (matched && matched.length > 2) {
      const locale = matched[1];
      const type = matched[2];
      if(messages[locale] == null){
        messages[locale] = {};
      }
      messages[locale][type] = locales(key);
    }
  });
  return messages
}

export function setI18nLanguage(i18n, locale) {
  if(i18n.global){
    i18n = i18n.global;
  }
  if (i18n.mode === 'legacy') {
      i18n.locale = locale
  } else {
      i18n.locale.value = locale
  }
  document.querySelector('html').setAttribute('lang', locale)
}