import { defineStore } from 'pinia'
import utils from '@/utils/utils.js'
import axios from 'axios'
import { i18n, loadLocaleMessages, setI18nLanguage } from '@/i18n'

const ENTRIES_PAGE = 12;
const ENTRIES_RESOURCES = 6;
const ENTRIES_INVESTOR=3;

function checkForNewPage(object, page, filter, text) {
    if (object.lastFilters.filter == filter && object.lastFilters.text == text) {
        if (object.items[page] != null && Object.keys(object.items[page]).length > 0) {
            return null;
        }
    } else {
        return -1;
    }
    return page;
}

function getEnty(object, slug) {
    var entry = null
    Object.keys(object).forEach(key => {
        if (object[key][slug]) {
            entry = object[key][slug];
        }
    });
    return entry;
}

export const contentStore = defineStore('content', {
    state: () => (
        {
            lang: i18n.global.locale,
            entries: {
                viewPage: 1,
                items: {},
                maxItems: 6,
            },
            releases: {
                viewPage: 1,
                lastFilters: {
                    filter: null,
                    text: null,
                },
                items: {},
                categories: {}
            },
            videos: {
                viewPage: 1,
                items: {},
                lastFilters: {
                    filter: null,
                    text: null,
                },
                categories: {}
            },
            starting: {
                viewPage: 1,
                itemSelected: null,
                items: {}
            },
            pagesFixed: {},

        }
    ),
    getters: {
        getEntries: (state) => (page) => {
            return state.entries.items[page];
        },
        getPageFixed: (state) => (slug) => {
            return state.pagesFixed[slug]
        },
        getReleases: (state) => (page) => {
            return state.releases.items[page];
        },
        setPageReleases: function (state) {
            return (page) => {
                return state.releases.viewPage = page
            }
        },
        getVideos: (state) => (page) => {
            return state.videos.items[page];
        },
        getStarting: (state) => (page) => {
            return state.starting.items[page];
        },
        getEntriesPage: (state) => {
            return state.entries.viewPage;
        },
        getReleasesPage: (state) => {
            return state.releases.viewPage;
        },
        getPagesRelease: (state) => (itemsPerPage = ENTRIES_RESOURCES) => {
            var pages = Math.ceil(state.releases.maxItems / itemsPerPage)
            return pages;
        },
        getVideosPage: (state) => {
            return state.videos.viewPage;
        },
        getPagesVideo: (state) => (itemsPerPage = ENTRIES_RESOURCES) => {
            var pages = Math.ceil(state.videos.maxItems / itemsPerPage)
            return pages;
        },
        setPageVideos: function (state) {
            return (page) => {
                return state.videos.viewPage = page
            }
        },
        getStartingPage: (state) => {
            return state.starting.viewPage;
        },
        setPageEntries: function (state) {
            return (page) => {
                return state.entries.viewPage = page
            }
        },
        getPagesEntries: (state) => (itemsPerPage = ENTRIES_PAGE) => {
            var pages = Math.ceil(state.entries.maxItems / itemsPerPage)
            return pages;
        },
        getPagesEntriesInvestor: (state) => (itemsPerPage = ENTRIES_INVESTOR) => {
            var pages = Math.ceil(state.entries.maxItems / itemsPerPage)
            return pages;
        },
        getEntryByCategoryBySLug: (state) => {
            return (slug, avoidSlug = null, page = 1) => {
                if (state.entries.items[page] == undefined || state.entries.items[page] == null) {
                    return null;
                }
                return Object.values(state.entries.items[page]).filter(element => {
                    return element.slug != slug && avoidSlug != element.slug;
                });
            }
        },
    },
    actions: {
        changeLang(lang) {
            if (lang !== this.lang) {
                this.lang = lang;
                this.entries.items = {};
                this.releases.items = {};
                this.videos.items = {};
                this.starting.items = {};
            }
        },
        async loadEntries({ page = 1, length = ENTRIES_PAGE } = {}) {
            let filtersSelect = [
                {
                    "name": "visible",
                    "search": 1
                }
            ];
            let data = {
                "force-language": this.lang,
                "page": page,
                "length": length,
                "filters": filtersSelect,
                "order": [
                    {
                        "name": 'publish_date',
                        "direction": 'desc'
                    }
                ],
            }
            let t = this;
            if (this.entries.items[page] != null && Object.keys(this.entries.items[page]).length > 0) {
                return null;
            }
            return new Promise((resolve) => {
                var promise = axios.post('entries', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        if (t.entries.items[page] == null) {
                            t.entries.items[page] = {}
                        }
                        t.entries.items[page][element.slug] = element;
                        // t.entries.items[element.slug] = element,
                        t.entries.viewPage = page,
                            t.entries.maxItems = result.data.recordsFiltered
                    });
                    resolve();
                });
            })
        },
        async loadEntry({ slug = 'default' } = {}) {
            var entry = getEnty(this.entries.items, slug)
            if (entry) {
                return entry
            }
            return new Promise((resolve) => {
                axios.get("entries/" + slug + '' + '?force-language=' + this.lang)
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        async loadEntriesInvestor({ page = 1, length = ENTRIES_INVESTOR } = {}) {
            let filtersSelect = [
                {
                    "name": "visible",
                    "search": 1
                },
                    {
                    "name": 'category_id',
                    "search": 2
                    }
                    
            ];
            let data = {
                "force-language": this.lang,
                "page": page,
                "length": length,
                "filters": filtersSelect,
                "order": [
                    {
                        "name": 'publish_date',
                        "direction": 'desc'
                    }
                ],
                
            }
            let t = this;
            if (this.entries.items[page] != null && Object.keys(this.entries.items[page]).length > 0) {
                return null;
            }
            return new Promise((resolve) => {
                var promise = axios.post('entries', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        if (t.entries.items[page] == null) {
                            t.entries.items[page] = {}
                        }
                        t.entries.items[page][element.slug] = element;
                        // t.entries.items[element.slug] = element,
                        t.entries.viewPage = page,
                            t.entries.maxItems = result.data.recordsFiltered
                    });
                    resolve();
                });
            })
        },
        async loadEntryInvestor({ slug = 'default' } = {}) {
            var entry = getEnty(this.entries.items, slug)
            if (entry) {
                return entry
            }
            return new Promise((resolve) => {
                axios.get("entries/" + slug + '' + '?force-language=' + this.lang)
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        async loadPageFixed({
            slug = 'default'
        } = {}) {
            let t = this;
            if (t.pagesFixed[slug]) {
                return
            }
            return new Promise((resolve) => {
                axios.get("fixed-pages/"+slug)
                    .then(function (result) {
                        resolve(result.data);
                        if (t.pagesFixed[result.data.slug] == null) {
                            t.pagesFixed[result.data.slug] = {}
                        }
                        t.pagesFixed[result.data.slug] = result.data

                    });
            });
        },
        async loadReleases({ page = 1, length = ENTRIES_RESOURCES, filter = this.releases.lastFilters.filter, text = this.releases.lastFilters.text } = {}) {
            page = checkForNewPage(this.releases, page, filter, text);
            if (page == -1) {
                page = 1;
                this.releases.items = {}
            } else if (page === null) {
                return;
            }
            let filtersSelect = [
                {
                    "name": "visible",
                    "search": 1
                },
                {
                    "name": "category_id",
                    "search": filter
                }

            ];

            let data = {
                "force-language": this.lang,
                "page": page,
                "length": length,
                "filters": filtersSelect,
                "order": [
                    {
                        "name": 'publish_date',
                        "direction": 'desc'
                    }
                ],
            }
            let t = this;
            if (this.releases.items[page] != null && Object.keys(this.releases.items[page]).length > 0) {
                return null;
            }
            return new Promise((resolve) => {
                var promise = axios.post('releases', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        if (t.releases.items[page] == null) {
                            t.releases.items[page] = {}
                        }
                        t.releases.items[page][element.slug] = element;
                        // t.releases.items[element.slug] = element,
                        t.releases.viewPage = page,
                            t.releases.maxItems = result.data.recordsFiltered,
                            t.releases.lastFilters = {
                                filter: filter,
                                text: text,
                            }
                    });
                    resolve();
                });
            })
        },
        async loadCategoriesReleases() {
            let t = this;
            if (this.releases.categories && Object.keys(this.releases.categories).length > 0) {
                return;
            }
            return new Promise((resolve) => {
                var promise = axios.post('/releases/categories', { "force-language": this.lang });
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        t.releases.categories[element.dataId] = element
                    });
                    resolve();
                });
            })
        },
        async loadVideos({ page = 1, length = ENTRIES_RESOURCES, filter = this.videos.lastFilters.filter, text = this.videos.lastFilters.text } = {}) {
            page = checkForNewPage(this.videos, page, filter, text);
            if (page == -1) {
                page = 1;
                this.videos.items = {}
            } else if (page === null) {
                return;
            }
            let filtersSelect = [
                {
                    "name": "visible",
                    "search": 1
                },
                {
                    "name": "category.id",
                    "search": filter
                }
            ];
            let filters = utils.generateTextFilters(text, ['name', 'header']);
            let data = {
                "force-language": this.lang,
                "page": page,
                "length": length,
                "filters": filtersSelect.concat(filters),
                "order": [
                    {
                        "name": 'publish_date',
                        "direction": 'desc'
                    }
                ],
            }
            let t = this;
            if (this.videos.items[page] != null && Object.keys(this.videos.items[page]).length > 0) {
                return null;
            }
            return new Promise((resolve) => {
                var promise = axios.post('how-to', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        if (t.videos.items[page] == null) {
                            t.videos.items[page] = {}
                        }
                        t.videos.items[page][element.slug] = element;
                        // t.videos.items[element.slug] = element,
                        t.videos.viewPage = page,
                            t.videos.maxItems = result.data.recordsFiltered
                        t.videos.lastFilters = {
                            filter: filter,
                            text: text,
                        }
                    });
                    resolve();
                });
            })
        },
        async loadVideosCategories() {
            let t = this;
            if (this.videos.categories && Object.keys(this.videos.categories).length > 0) {
                return;
            }
            return new Promise((resolve) => {
                var promise = axios.post('/how-to/categories', { "force-language": this.lang });
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        t.videos.categories[element.dataId] = element
                    });
                    resolve();
                });
            })
        },
        async loadStarting({ page = 1, length = 1 } = {}) {
            let filtersSelect = [
                {
                    "name": "visible",
                    "search": 1
                }
            ];
            let data = {
                "force-language": this.lang,
                "page": page,
                "length": length,
                "filters": filtersSelect,
                "order": [
                    {
                        "name": 'publish_date',
                        "direction": 'desc'
                    }
                ],
            }
            let t = this;
            if (this.starting.items[page] != null && Object.keys(this.starting.items[page]).length > 0) {
                return null;
            }
            return new Promise((resolve) => {
                var promise = axios.post('starting', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        if (t.starting.items[page] == null) {
                            t.starting.items[page] = {}
                        }
                        t.starting.itemSelected = element;
                        // t.starting.items[element.slug] = element,
                        t.starting.viewPage = page,
                            t.starting.maxItems = result.data.recordsFiltered
                    });
                    resolve();
                });
            })
        },
    }
})