import {
  createRouter, createWebHistory
} from 'vue-router'

import { i18n, setI18nLanguage } from '@/i18n'
import { contentStore } from '@/stores/contents'

const routes = [
  {
    path: '/:lang(es|en|it|de|fr|nl)?',
    name: "language-part",
    children: [
      {
        path: i18n.global.t('urls.home'),
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: {
          title: i18n.global.t('meta.home_title'),
          description: i18n.global.t('meta.home_description')
        }
      },
      {
        path: i18n.global.t('urls.about'),
        name: "about",
        component: () => import('@/views/about-us.vue'),
        meta: {
          title: i18n.global.t('meta.about_title'),
          description: i18n.global.t('meta.about_description')
        }
      },
      
      {
        path: i18n.global.t('urls.features'),
        name: "features",
        component: () => import('@/views/features.vue'),
        meta: {
          title: i18n.global.t('meta.features_title'),
          description: i18n.global.t('meta.features_description')
        }
      },
      {
        path: i18n.global.t('urls.contact'),
        name: "contact",
        component: () => import('@/views/contact.vue'),
        meta: {
          title: i18n.global.t('meta.contact_title'),
          description: i18n.global.t('meta.contact_description')
        }
      },
      {
        path: i18n.global.t('urls.privacy'),
        name: 'privacy',
        component: () => import('@/views/privacy-policy.vue'),
        meta: {
          title: i18n.global.t('meta.privacy_policy_title'),
          description: i18n.global.t('meta.privacy_policy_description')
        }
      },
      {
        path: i18n.global.t('urls.demo-confirmed'),
        name: 'demo-confirmed',
        component: () => import('@/views/demo-confirmada.vue')
       
      },
      {
        path: i18n.global.t('urls.demo'),
        name: 'demo',
        component: () => import('@/views/demo.vue'),
        meta: {
          title: i18n.global.t('meta.demo_title'),
          description: i18n.global.t('meta.demo_description')
        }
      },
      {
        path: i18n.global.t('urls.thank-u-newsletter'),
        name: 'thank-u-newsletter',
        component: () => import('@/views/gracias-newsletter.vue')
      },

      //SUPORT
      {
        path: i18n.global.t('urls.support'),
        name: 'support',
        component: () => import('@/views/support.vue'),
        meta: {
          title: i18n.global.t('meta.soporte_title'),
          description: i18n.global.t('meta.soporte_description')
        }
      },
      {
        path: i18n.global.t('urls.support-started'),
        name: 'support-started',
        component: () => import('@/views/support-getting-started.vue'),
        meta: {
          title: i18n.global.t('meta.support_started_title'),
          description: i18n.global.t('meta.support_started_description')
        }
      },
      {
        path: i18n.global.t('urls.support-release'),
        name: 'support-release',
        component: () => import('@/views/support-release-notes.vue'),
        meta: {
          title: i18n.global.t('meta.support_release_title'),
          description: i18n.global.t('meta.support_release_description')
        }
      },
      {
        path: i18n.global.t('urls.support-how'),
        name: 'support-how',
        component: () => import('@/views/support-how-to-videos.vue'),
        meta: {
          title: i18n.global.t('meta.support_how_title'),
          description: i18n.global.t('meta.support_how_description')
        }
      },
      {
        path: i18n.global.t('urls.blog'),
        name: 'blog',
        component: () => import('@/views/blog.vue'),
        meta: {
          title: i18n.global.t('meta.blog_title'),
          description: i18n.global.t('meta.blog_description')
        }
      },
      {
        path: i18n.global.t('urls.blog-page'),
        name: 'blog-page',
        props: true,
        component: () => import('@/views/page-blog.vue')
   
      },
      {
        path: i18n.global.t('urls.investor'),
        name: 'investor',
        props: true,
        component: () => import('@/views/investor.vue')
   
      },
      {
        path: '*',
        redirect: to => {
          return {
            path: '/',
            query: {
              httpstatus: 301,
            }
          }
        }
      },
      {
        path: "/404",
        redirect: to => {
          return {
            path: '/',
            query: {
              httpstatus: 301,
            }
          }
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.params.lang == from.params.lang) {
      return { top: 0 }
    }

  },
  routes,
})

router.beforeEach((to, beforeEachfrom, next) => {

  console.log()

  const store = contentStore();

  /* Clean Special Name */
  if (to.name == 'language-part') {
    to.name = null;
  }
  if (to.name == 'investor') {
    document.querySelector('body').classList = 'investor-page'
  }

  /* If is null */
  if (to.name == null || to.params.lang == null || to.params.lang.length < 2) {
    store.lang = i18n.global.locale;
    to.params.lang = store.lang;
    next({
      name: to.name ?? "home",
      params: to.params,
    });
    return;
  }

  /* If is set */
  if (to.params.lang && to.params.lang != store.lang) {
    store.changeLang(to.params.lang);
    setI18nLanguage(i18n, store.lang);
    translateAllRoutes(router);
    next({
      name: to.name,
      path: i18n.global.t('urls.' + to.name),
      params: to.params,
    });
    return
  }
  if (to.query.httpstatus) {
    let metaComponent = document.createElement('meta');
    metaComponent.name = "prerender-status-code";
    metaComponent.content = to.query.httpstatus;
    document.head.appendChild(metaComponent);
    if (to.query.httpstatus == 301) {
      metaComponent = document.createElement('meta');
      metaComponent.name = "prerender-header";
      metaComponent.content = "Location: " + location.href;
      document.head.appendChild(metaComponent);
    }
    delete to.query.httpstatus;
  }
  document.title= to.meta.title ?? 'R-SAMi: An Intelligent Software made by Amazon DSPs for DSPs';
  document.description = to.meta.description ??  'R-SAMi: An Intelligent Software made by Amazon DSPs for DSPs';
  document.head.querySelector("[name=title]").content = to.meta.title ?? "R-SAMi: An Intelligent Software made by Amazon DSPs for DSPs";
  document.head.querySelector("[name=description]").content = to.meta.description ?? "R-SAMi: An Intelligent Software made by Amazon DSPs for DSPs";

  next();
});


function translateAllRoutes(router) {
  routes[0].children.forEach(function (r) {
    router.removeRoute(r.name);
    r.path = i18n.global.t('urls.' + r.name);
    router.addRoute('language-part', r);
  })
}

export default router